@charset "UTF-8";

#fb-editor {
    min-height: 100vh;
}
.form-wrap.form-builder .stage-wrap {
    /*width: calc(76% - 10px);*/
}
.form-wrap.form-builder .cb-wrap {
    width: 24%;
}
input.prof-pic-upld-ctrl {
    font-size: 16px;
}
/*.form-wrap.form-builder .form-control {
    padding: 8px 12px !important;
    line-height: 1.5;
    font-size: 16px;
    height: unset;
}*/
.form-wrap.form-builder .frmb .prev-holder select, .form-wrap.form-builder .frmb .prev-holder input[type=text], .form-wrap.form-builder .frmb .prev-holder input[type=number] {
    background-color: #fff;
    border: 1px solid #7d8998;
    box-shadow: none;
    height: 40px;
}
.form-wrap.form-builder .frmb .prev-holder textarea {
    background-color: #fff;
    border: 1px solid #7d8998;
    box-shadow: none;
    height: 80px;
}
.form-wrap.form-builder .frmb .prev-holder select, .form-wrap.form-builder .frmb .prev-holder input[type=text], .form-wrap.form-builder .frmb .prev-holder input[type=number] {
    /*height: 54px;*/
}
.fb-render label {
    /*padding-bottom: 0.3rem;*/
    /*padding-left: 4px;*/
    /*color: #7D7E75 !important;*/
}
.fb-render .form-control[type=file] {
    padding-top: 10px !important;
}
.fb-render .form-control, .fb-render .form-select {
    background-color: #ffffff;
    background-clip: border-box;
    border: 1px solid #7d8998;
    /*margin-bottom: 16px;*/
    padding: 2px 12px !important;
    transition: border-color 0.25s ease 0s;
    height: 40px !important;
}
.rendered-form .form-group {
    margin-bottom: 16px;
}
.fb-render {
    /*padding: 25px 52px 16px 40px;*/
    /*box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;*/
}
.formbuilder-required {
    padding-left: 4px;
    color: #f00 !important;
    /*vertical-align: super;*/
}
.formbuilder-button {
    margin-top: 20px !important;
    margin-bottom: 5px !important;
    /*background: grey;*/
}
.formbuilder-button .btn {
    padding-left: 25px !important;
    padding-right: 25px !important;
}
.formbuilder-button .btn-default {
    border: 2px solid #616161;
    color:#212121 ;
}
.formbuilder-header h1 {
    padding-top: 20px !important;
}
.formbuilder-header hr {
    margin-top: 30px;
}
.formbuilder-paragraph {
    padding-top: 16px;
}
.form-wrap.form-builder .frmb-control li {
    border: 1px solid #757575;
    box-shadow: none;
    font-weight: 500;
    padding-left: 20px;
}
.form-wrap.form-builder .frmb-control li span {
    font-weight: 600;
}
.fb-render .section-group {
    font-size: 1.875rem;
    /*padding-bottom: 15px;*/
    /*color: rgb(0, 0, 0, 0.7)*/
}
.template-name {
    padding-bottom: 0.3rem;
}
.template-design {
    /*padding-right: 6px;*/
    padding-bottom: 12px;
}
.template-desc {
    padding-top: 16px;
    padding-bottom: 0.3rem;
}
#txtTemplateDesc {
    min-height: 120px;
}
form-designer .section-group {
    font-size: 20px;
    font-weight: 600;
    color: #011936;
    padding-bottom: 8px;
}
.form-wrap.form-builder .form-control {
    line-height: 1.5;
    font-size: 1rem;
    background-color: #ffffff;
    background-clip: border-box;
    /*color: rgba(0, 0, 0, 0.87) !important;*/
    /*line-height: 1rem !important;*/
    /*height: auto;*/
    padding: 2px 12px !important;
    transition: border-color 0.25s ease 0s;
    height: 40px;
    border: 1px solid #7d8998;
}
.form-wrap.form-builder .frmb li.form-field {
    padding: 0px;
    margin-bottom: 0px;
}
.form-wrap.form-builder .frmb .field-label {
    color: #1F1F1F;
    margin-bottom: 0.3rem;
    line-height: 27px;
    font-size: 16px;
    font-weight: normal;
    padding-top: 16px;
}
.field-chkAcctType-preview .checkbox-group {
    max-height: 200px;
    overflow-y: scroll;
    /*margin-top: 8px;*/
    /*margin-bottom: 8px;*/
    padding-left: 16px;
    border: 1px solid rgba(0, 0, 0, 0.7);
}
.fb-render-preview .field-chkAcctType {
    max-height: 200px;
    overflow-y: scroll;
    /*margin-top: 8px;*/
    margin-bottom: 16px;
    padding-left: 16px;
    border: 1px solid #7d8998;
}

.fb-render-preview label {
    padding-bottom: 12px;
    color: #7D7E75 !important;
}

.form-designer .formbuilder-checkbox {
    padding-left: 12px;
}
.navbar-dropdown {
    left: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.45s ease;
    z-index: 1030;
    background: #282828;
}

    .navbar-dropdown .navbar-logo {
        margin-right: 0.7rem;
        transition: margin 0.3s ease-in-out;
        vertical-align: middle;
    }

    .navbar-dropdown .navbar-caption {
        font-weight: 700;
        white-space: normal;
        vertical-align: -4px;
        line-height: 3.125rem !important;
    }

    .navbar-dropdown.navbar-fixed-top {
        position: fixed;
    }

    .navbar-dropdown .navbar-brand span {
        vertical-align: -4px;
    }

    .navbar-dropdown.bg-color.transparent {
        background: none;
    }

    .navbar-dropdown .navbar-close {
        left: 0.6875rem;
        position: fixed;
        top: 0.75rem;
        z-index: 1000;
    }

    .navbar-dropdown .hamburger-icon {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        -webkit-box-shadow: 0 -6px 0 1px #282828,0 0 0 1px #282828,0 6px 0 1px #282828;
        -moz-box-shadow: 0 -6px 0 1px #282828,0 0 0 1px #282828,0 6px 0 1px #282828;
        box-shadow: 0 -6px 0 1px #282828,0 0 0 1px #282828,0 6px 0 1px #282828;
    }

.dropdown-menu .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
    border-bottom: 0.35em solid transparent;
    border-left: 0.35em solid;
    border-right: 0;
    border-top: 0.35em solid transparent;
    margin-left: 0.3rem;
}

.dropdown-menu .dropdown-item:focus {
    outline: 0;
}

.nav-dropdown {
    font-size: 0.75rem;
    font-weight: 500;
    height: auto !important;
}

    .nav-dropdown .nav-btn {
        padding-left: 1rem;
    }

    .nav-dropdown .link {
        margin: .667em 1.667em;
        /*font-weight: 500;*/
        padding: 0;
        transition: color .2s ease-in-out;
    }

        .nav-dropdown .link.dropdown-toggle {
            margin-right: 2.583em;
        }

            .nav-dropdown .link.dropdown-toggle::after {
                margin-left: .25rem;
                border-top: 0.35em solid;
                border-right: 0.35em solid transparent;
                border-left: 0.35em solid transparent;
                border-bottom: 0;
            }

            .nav-dropdown .link.dropdown-toggle[aria-expanded="true"] {
                margin: 0;
                padding: 0.667em 3.263em 0.667em 1.667em;
            }

        .nav-dropdown .link::after,
        .nav-dropdown .dropdown-item::after {
            color: inherit;
        }

/*    .nav-dropdown .btn {
        font-size: 0.75rem;
        font-weight: 700;
        letter-spacing: 0;
        margin-bottom: 0;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }*/

    .nav-dropdown .dropdown-menu {
        border-radius: 0;
        border: 0;
        left: 0;
        margin: 0;
        padding-bottom: 1.25rem;
        padding-top: 1.25rem;
        position: relative;
    }

    .nav-dropdown .dropdown-submenu {
        margin-left: 0.125rem;
        top: 0;
    }

    .nav-dropdown .dropdown-item {
        font-weight: 500;
        line-height: 2;
        padding: 0.3846em 4.615em 0.3846em 1.5385em;
        position: relative;
        transition: color .2s ease-in-out, background-color .2s ease-in-out;
    }

        .nav-dropdown .dropdown-item::after {
            margin-top: -0.3077em;
            position: absolute;
            right: 1.1538em;
            top: 50%;
        }

        .nav-dropdown .dropdown-item:focus, .nav-dropdown .dropdown-item:hover {
            background: none;
        }

section {
    background-color: #ffffff;
}

body {
    font-style: normal;
    line-height: 1.5;
    font-weight: 400;
    color: #07122b; /*#07122b; #4c4c4c; #374e5a; #0b3047;*/
    position: relative;
}

button {
    background-color: transparent;
    border-color: transparent;
}

section,
.container,
.container-fluid {
    position: relative;
    word-wrap: break-word;
}

.article .lead p,
.article .lead ul,
.article .lead ol,
.article .lead pre,
.article .lead blockquote {
    margin-bottom: 0;
}

a {
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
    /*color: #235ec9 !important;*/
}

    a:hover {
        text-decoration: underline !important;
    }

.aob-section-title {
    font-style: normal;
    line-height: 1.3;
}

.aob-section-subtitle {
    line-height: 1.3;
}

.aob-text {
    font-style: normal;
    line-height: 1.7;
}

h1,
h2,
h3,
h4,
h5,
h6,
.display-1,
.display-2,
.display-4,
.display-5,
.display-7,
span,
p,
a {
    /*line-height: 1;*/
    word-break: break-word;
    word-wrap: break-word;
    font-weight: 400;
}

b,
strong {
    font-weight: bold;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
    transition-delay: 9999s;
    -webkit-transition-property: background-color, color;
    transition-property: background-color, color;
}

textarea[type=hidden] {
    display: none;
}

section {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.hidden {
    visibility: hidden;
}

.aob-white {
    color: #ffffff;
}

.aob-black {
    color: #333333;
}

.aob-bg-white {
    background-color: #ffffff;
}

.aob-bg-black {
    background-color: #000000;
}

/*! Text-aligns */
.align-left {
    text-align: left !important;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

/*! Font-weight  */
.aob-light {
    font-weight: 300 !important;
}

.aob-regular {
    font-weight: 400;
}

.aob-semibold {
    font-weight: 500;
}

.aob-bold {
    font-weight: 700;
}

img,
iframe {
    display: block;
    width: 100%;
}

.card {
    background-color: transparent;
    border: none;
}

.card-box {
    width: 100%;
}

.card-img {
    text-align: center;
    flex-shrink: 0;
    -webkit-flex-shrink: 0;
}

    .card-img img {
        width: 100%;
    }

.aob-section-btn {
    margin-left: -0.6rem;
    margin-right: -0.6rem;
}

/*.btn {
    font-weight: 400;
    border-width: 1px;
    font-style: normal;
    margin: 0.6rem 0.6rem;
    white-space: normal;
    transition: all 0.2s ease-in-out;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    word-break: break-word;
    min-height: 55px;
    min-width: 170px !important;
}*/

/*.btn-sm {
    font-weight: 600;
    letter-spacing: 0px;
    transition: all 0.3s ease-in-out;
}

.btn-md {
    font-weight: 600;
    letter-spacing: 0px;
    transition: all 0.3s ease-in-out;
}

.btn-lg {
    font-weight: 600;
    letter-spacing: 0px;
    transition: all 0.3s ease-in-out;
}
*/
.btn-form {
    margin: 0;
}

    .btn-form:hover {
        cursor: pointer;
    }

nav .aob-section-btn {
    margin-left: 0rem;
    margin-right: 0rem;
}

[type=submit] {
    -webkit-appearance: none;
}

.bg-gradient {
    background-size: 200% 200%;
    animation: gradient-animation 5s infinite alternate;
    -webkit-animation: gradient-animation 5s infinite alternate;
}

.menu .navbar-brand {
    display: -webkit-flex;
}

    .menu .navbar-brand span {
        display: flex;
        display: -webkit-flex;
    }

    .menu .navbar-brand .navbar-caption-wrap {
        display: -webkit-flex;
    }

/*.menu .navbar-brand .navbar-logo img {
    display: -webkit-flex;
    width: auto;
}*/

@media (min-width: 768px) and (max-width: 991px) {
    .menu .navbar-toggleable-sm .navbar-nav {
        display: -ms-flexbox;
    }
}

@media (max-width: 991px) {
    .menu .navbar-collapse {
        max-height: 93.5vh;
    }

        .menu .navbar-collapse.show {
            overflow: auto;
        }
}

@media (min-width: 992px) {
    .menu .navbar-nav.nav-dropdown {
        display: -webkit-flex;
    }

    .menu .navbar-toggleable-sm .navbar-collapse {
        display: -webkit-flex !important;
    }

    .menu .collapsed .navbar-collapse {
        max-height: 93.5vh;
    }

        .menu .collapsed .navbar-collapse.show {
            overflow: auto;
        }
}

@media (max-width: 767px) {
    .menu .navbar-collapse {
        max-height: 80vh;
    }
}

.navbar {
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    -webkit-align-items: center;
    -webkit-justify-content: space-between;
}

.navbar-collapse {
    -webkit-flex-basis: 100%;
    -webkit-flex-grow: 1;
    -webkit-align-items: center;
}

.nav-dropdown .link {
    padding: 0.667em 1.667em !important;
    margin: 0 !important;
}

.nav {
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
}

.row {
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
}

.justify-content-center {
    -webkit-justify-content: center;
}

.form-inline {
    display: -webkit-flex;
}

.card-wrapper {
    -webkit-flex: 1;
}

.carousel-control {
    z-index: 10;
    display: -webkit-flex;
}

.carousel-controls {
    display: -webkit-flex;
}

.form-group:focus {
    outline: none;
}

input[type=range] {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.carousel-item {
    text-align: center;
}

    .carousel-item img {
        margin: auto;
    }

.navbar-toggler {
    align-self: flex-start;
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

    .navbar-toggler:focus,
    .navbar-toggler:hover {
        text-decoration: none;
        box-shadow: none;
    }

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}

.navbar-toggler-left {
    position: absolute;
    left: 1rem;
}

.navbar-toggler-right {
    position: absolute;
    right: 1rem;
}

.card-img {
    width: auto;
}

.menu .navbar.collapsed:not(.beta-menu) {
    flex-direction: column;
}

html,
body {
    height: auto;
    min-height: 100vh;
}

.dropup .dropdown-toggle::after {
    display: none;
}

.form-asterisk {
    font-family: initial;
    position: absolute;
    top: -2px;
    font-weight: normal;
}

.form-control-label {
    position: relative;
    cursor: pointer;
    margin-bottom: 0.357em;
    padding: 0;
}


.form-active .form-control:invalid {
    border-color: red;
}

form .row {
    margin-left: -0.6rem;
    margin-right: -0.6rem;
}

    form .row [class*=col] {
        padding-left: 0.6rem;
        padding-right: 0.6rem;
    }

form .aob-section-btn {
    margin: 0;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
}

form .btn {
    display: flex;
/*    padding: 0.6rem 1.2rem;
*/    margin: 0;
}

form .form-check-input {
    margin-top: 0.5;
}

textarea.form-control {
    line-height: 1.5rem !important;
}

.form-group {
    margin-bottom: 1.2rem;
}

form .btn {
    min-height: 48px;
}

    .form-control:focus {
        box-shadow: none;
    }

:focus {
    outline: none;
}

ul,
ol,
pre,
blockquote {
    margin-bottom: 2.3125rem;
}

.mt-4 {
    margin-top: 2rem !important;
}

.mb-4 {
    margin-bottom: 2rem !important;
}

@media (min-width: 992px) {
    .container {
        /*padding-left: 16px;
        padding-right: 16px;*/
    }

    .row {
       /* margin-left: -16px;
        margin-right: -16px;*/
    }

        .row > [class*=col] {
            /*padding-left: 16px;
            padding-right: 16px;*/
        }
}

@media (min-width: 768px) {
    .container-fluid {
        padding-left: 32px;
        padding-right: 32px;
    }
}

.card-wrapper,
.item-wrapper {
    overflow: hidden;
}

.item {
    position: relative;
}

.dropdown-menu .dropdown-menu {
    left: 100%;
}

.dropdown-item + .dropdown-menu {
    display: none;
}

.dropdown-item:hover + .dropdown-menu,
.dropdown-menu:hover {
    display: block;
}

body {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

.display-1 {
    font-family: 'Roboto', sans-serif;
    font-size: 4.6rem;
    line-height: 1.1;
    color: #0b3047;
}

.display-2 {
    font-family: 'Roboto', sans-serif;
    font-size: 3rem;
    line-height: 1.138;
    font-weight: 700;
    color: #07122b;
    /*letter-spacing: 1px;*/
}

.display-4 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.25rem;
    line-height: 1.5;
}

.display-5 {
    font-family: 'Roboto', sans-serif;
    font-size: 2rem;
    line-height: 1.35;
    font-weight: 600;
    /*color: #333333;*/
}

.display-6 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.3rem;
    line-height: 1.5;
}

.display-7 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
    line-height: 1.5;
}

.display-8 {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    line-height: 1.6;
    font-weight: 400;
}

.display-9 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.4rem;
    line-height: 1.5;
    color: #525252;
    font-weight: 600;
}

.display-10 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.1rem;
    line-height: 1.5;
    color: #525252;
    font-weight: 600;
}
.display-11 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    line-height: 1.5;
}
.display-15 {
    font-family: 'Roboto', sans-serif;
    font-size: 3.545rem;
    line-height: 1.5;
}
.display-16 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.8rem;
    line-height: 1.5;
}
.display-17 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.52rem;
    line-height: 1.5;
    color: #333333;
}
.display-18 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.38rem;
    line-height: 1.5;
    color: #333333;
}
.display-19 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.25rem;
    line-height: 1.5;
    color: #333333;
}
.display-20 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.1rem;
    line-height: 1.5;
    color: #333333;
}
.display-21 {
    font-family: 'Roboto', sans-serif;
    font-size: 0.9rem;
    line-height: 1.4;
    color: #333333;
}
.section-group {
    font-family: 'Roboto', sans-serif;
    font-size: 2rem;
    line-height: 1.35;
    /*color: #011936 !important;*/
    font-weight: 500;
    padding-top: 10px;
}



@media (max-width: 992px) {
    .display-1 {
        font-size: 3.68rem;
    }
}

@media (max-width: 768px) {
    .display-1 {
        font-size: 3.22rem;
        font-size: calc( 2.26rem + (4.6 - 2.26) * ((100vw - 20rem) / (48 - 20)));
        line-height: calc( 1.1 * (2.26rem + (4.6 - 2.26) * ((100vw - 20rem) / (48 - 20))));
    }

    .display-2 {
        font-size: 2.4rem;
        font-size: calc( 1.7rem + (3 - 1.7) * ((100vw - 20rem) / (48 - 20)));
        line-height: calc( 1.3 * (1.7rem + (3 - 1.7) * ((100vw - 20rem) / (48 - 20))));
    }

    .display-4 {
        font-size: 0.88rem;
        font-size: calc( 1.0350000000000001rem + (1.1 - 1.0350000000000001) * ((100vw - 20rem) / (48 - 20)));
        line-height: calc( 1.4 * (1.0350000000000001rem + (1.1 - 1.0350000000000001) * ((100vw - 20rem) / (48 - 20))));
    }

    .display-5 {
        font-size: 1.76rem;
        font-size: calc( 1.42rem + (2.2 - 1.42) * ((100vw - 20rem) / (48 - 20)));
        line-height: calc( 1.4 * (1.42rem + (2.2 - 1.42) * ((100vw - 20rem) / (48 - 20))));
    }

    .display-7 {
        font-size: 0.96rem;
        font-size: calc( 1.07rem + (1.2 - 1.07) * ((100vw - 20rem) / (48 - 20)));
        line-height: calc( 1.4 * (1.07rem + (1.2 - 1.07) * ((100vw - 20rem) / (48 - 20))));
    }
}
/* Buttons */
/*.btn {
    padding: 0.6rem 1.2rem;
    border-radius: 4px;
}

.btn-sm {
    padding: 0.6rem 1.2rem;
    border-radius: 4px;
}

.btn-md {
    padding: 0.6rem 1.2rem;
    border-radius: 4px;
}

.btn-lg {
    padding: 1rem 2.6rem;
    border-radius: 4px;
}*/

.bg-primary {
    background-color: #6592e6 !important;
}

.bg-success {
    background-color: #40b0bf !important;
}

.bg-info {
    background-color: #47b5ed !important;
}

.bg-warning {
    background-color: #ffe161 !important;
}

.bg-danger {
    background-color: #ff9966 !important;
}

/*.btn-primary {
    background: linear-gradient(-63.4349488229deg,#0099f6 0,#0099f6 100%);
    border-color: #6592e6 !important;
    color: #ffffff !important;
}

    .btn-primary:hover,
    .btn-primary:focus,
    .btn-primary.active {
        color: #ffffff !important;
        background: linear-gradient(-63.4349488229deg,#153879 0,#114FA6 100%);
        border-color: #2260d2 !important;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
        text-decoration: none;
    }

    .btn-primary.disabled,
    .btn-primary:disabled {
        color: #ffffff !important;
        background-color: #2260d2 !important;
        border-color: #2260d2 !important;
    }

.btn-primary {
    padding: 0;
}*/

/*.btn-secondary,
.btn-secondary:active {
    background-color: #ff6666 !important;
    border-color: #ff6666 !important;
    color: #ffffff !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}

    .btn-secondary:hover,
    .btn-secondary:focus,
    .btn-secondary.focus,
    .btn-secondary.active {
        color: #ffffff !important;
        background-color: #ff0f0f !important;
        border-color: #ff0f0f !important;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    }

    .btn-secondary.disabled,
    .btn-secondary:disabled {
        color: #ffffff !important;
        background-color: #ff0f0f !important;
        border-color: #ff0f0f !important;
    }

.btn-info,
.btn-info:active {
    background-color: #47b5ed !important;
    border-color: #47b5ed !important;
    color: #ffffff !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}

    .btn-info:hover,
    .btn-info:focus,
    .btn-info.focus,
    .btn-info.active {
        color: #ffffff !important;
        background-color: #148cca !important;
        border-color: #148cca !important;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    }

    .btn-info.disabled,
    .btn-info:disabled {
        color: #ffffff !important;
        background-color: #148cca !important;
        border-color: #148cca !important;
    }

.btn-success,
.btn-success:active {
    background-color: #40b0bf !important;
    border-color: #40b0bf !important;
    color: #ffffff !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}

    .btn-success:hover,
    .btn-success:focus,
    .btn-success.focus,
    .btn-success.active {
        color: #ffffff !important;
        background-color: #2a747e !important;
        border-color: #2a747e !important;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    }

    .btn-success.disabled,
    .btn-success:disabled {
        color: #ffffff !important;
        background-color: #2a747e !important;
        border-color: #2a747e !important;
    }

.btn-warning,
.btn-warning:active {
    background-color: #ffe161 !important;
    border-color: #ffe161 !important;
    color: #614f00 !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}

    .btn-warning:hover,
    .btn-warning:focus,
    .btn-warning.focus,
    .btn-warning.active {
        color: #0a0800 !important;
        background-color: #ffd10a !important;
        border-color: #ffd10a !important;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    }

    .btn-warning.disabled,
    .btn-warning:disabled {
        color: #614f00 !important;
        background-color: #ffd10a !important;
        border-color: #ffd10a !important;
    }

.btn-danger,
.btn-danger:active {
    background-color: #ff9966 !important;
    border-color: #ff9966 !important;
    color: #ffffff !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}

    .btn-danger:hover,
    .btn-danger:focus,
    .btn-danger.focus,
    .btn-danger.active {
        color: #ffffff !important;
        background-color: #ff5f0f !important;
        border-color: #ff5f0f !important;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    }

    .btn-danger.disabled,
    .btn-danger:disabled {
        color: #ffffff !important;
        background-color: #ff5f0f !important;
        border-color: #ff5f0f !important;
    }

.btn-white,
.btn-white:active {
    background-color: #fafafa !important;
    border-color: #fafafa !important;
    color: #7a7a7a !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-more {
    width: 40px;
    height: 40px;
    min-width: 80px !important;
    min-height: 40px !important;
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.btn-more,
.btn-more:active,
.btn-more:hover,
.btn-more:focus,
.btn-more.focus,
.btn-more.active {
    background-color: #fafafa !important;
    border-color: #fafafa !important;
    box-shadow: none !important;
}
    .btn-white:hover,
    .btn-white:focus,
    .btn-white.focus,
    .btn-white.active {
        color: #4f4f4f !important;
        background-color: #cfcfcf !important;
        border-color: #cfcfcf !important;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    }

    .btn-white.disabled,
    .btn-white:disabled {
        color: #7a7a7a !important;
        background-color: #cfcfcf !important;
        border-color: #cfcfcf !important;
    }

.btn-black,
.btn-black:active {
    background-color: #232323 !important;
    border-color: #232323 !important;
    color: #ffffff !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}

    .btn-black:hover,
    .btn-black:focus,
    .btn-black.focus,
    .btn-black.active {
        color: #ffffff !important;
        background-color: #000000 !important;
        border-color: #000000 !important;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    }

    .btn-black.disabled,
    .btn-black:disabled {
        color: #ffffff !important;
        background-color: #000000 !important;
        border-color: #000000 !important;
    }

.btn-primary-outline,
.btn-primary-outline:active {
    background-color: transparent !important;
    border-color: transparent;
    color: #6592e6;
}

    .btn-primary-outline:hover,
    .btn-primary-outline:focus,
    .btn-primary-outline.focus,
    .btn-primary-outline.active {
        color: #2260d2 !important;
        background-color: transparent !important;
        border-color: transparent !important;
        box-shadow: none !important;
    }

    .btn-primary-outline.disabled,
    .btn-primary-outline:disabled {
        color: #ffffff !important;
        background-color: #6592e6 !important;
        border-color: #6592e6 !important;
    }

.btn-secondary-outline,
.btn-secondary-outline:active {
    background-color: transparent !important;
    border-color: transparent;
    color: #ff6666;
}

    .btn-secondary-outline:hover,
    .btn-secondary-outline:focus,
    .btn-secondary-outline.focus,
    .btn-secondary-outline.active {
        color: #ff0f0f !important;
        background-color: transparent !important;
        border-color: transparent !important;
        box-shadow: none !important;
    }

    .btn-secondary-outline.disabled,
    .btn-secondary-outline:disabled {
        color: #ffffff !important;
        background-color: #ff6666 !important;
        border-color: #ff6666 !important;
    }

.btn-info-outline,
.btn-info-outline:active {
    background-color: transparent !important;
    border-color: transparent;
    color: #47b5ed;
}

    .btn-info-outline:hover,
    .btn-info-outline:focus,
    .btn-info-outline.focus,
    .btn-info-outline.active {
        color: #148cca !important;
        background-color: transparent !important;
        border-color: transparent !important;
        box-shadow: none !important;
    }

    .btn-info-outline.disabled,
    .btn-info-outline:disabled {
        color: #ffffff !important;
        background-color: #47b5ed !important;
        border-color: #47b5ed !important;
    }

.btn-success-outline,
.btn-success-outline:active {
    background-color: transparent !important;
    border-color: transparent;
    color: #40b0bf;
}

    .btn-success-outline:hover,
    .btn-success-outline:focus,
    .btn-success-outline.focus,
    .btn-success-outline.active {
        color: #2a747e !important;
        background-color: transparent !important;
        border-color: transparent !important;
        box-shadow: none !important;
    }

    .btn-success-outline.disabled,
    .btn-success-outline:disabled {
        color: #ffffff !important;
        background-color: #40b0bf !important;
        border-color: #40b0bf !important;
    }

.btn-warning-outline,
.btn-warning-outline:active {
    background-color: transparent !important;
    border-color: transparent;
    color: #ffe161;
}

    .btn-warning-outline:hover,
    .btn-warning-outline:focus,
    .btn-warning-outline.focus,
    .btn-warning-outline.active {
        color: #ffd10a !important;
        background-color: transparent !important;
        border-color: transparent !important;
        box-shadow: none !important;
    }

    .btn-warning-outline.disabled,
    .btn-warning-outline:disabled {
        color: #614f00 !important;
        background-color: #ffe161 !important;
        border-color: #ffe161 !important;
    }

.btn-danger-outline,
.btn-danger-outline:active {
    background-color: transparent !important;
    border-color: transparent;
    color: #ff9966;
}

    .btn-danger-outline:hover,
    .btn-danger-outline:focus,
    .btn-danger-outline.focus,
    .btn-danger-outline.active {
        color: #ff5f0f !important;
        background-color: transparent !important;
        border-color: transparent !important;
        box-shadow: none !important;
    }

    .btn-danger-outline.disabled,
    .btn-danger-outline:disabled {
        color: #ffffff !important;
        background-color: #ff9966 !important;
        border-color: #ff9966 !important;
    }

.btn-black-outline,
.btn-black-outline:active {
    background-color: transparent !important;
    border-color: transparent;
    color: #232323;
}

    .btn-black-outline:hover,
    .btn-black-outline:focus,
    .btn-black-outline.focus,
    .btn-black-outline.active {
        color: #000000 !important;
        background-color: transparent !important;
        border-color: transparent !important;
        box-shadow: none !important;
    }

    .btn-black-outline.disabled,
    .btn-black-outline:disabled {
        color: #ffffff !important;
        background-color: #232323 !important;
        border-color: #232323 !important;
    }

.btn-white-outline,
.btn-white-outline:active {
    background-color: transparent !important;
    border-color: transparent;
    color: #fafafa;
}

    .btn-white-outline:hover,
    .btn-white-outline:focus,
    .btn-white-outline.focus,
    .btn-white-outline.active {
        color: #cfcfcf !important;
        background-color: transparent !important;
        border-color: transparent !important;
        box-shadow: none !important;
    }

    .btn-white-outline.disabled,
    .btn-white-outline:disabled {
        color: #7a7a7a !important;
        background-color: #fafafa !important;
        border-color: #fafafa !important;
    }
*/
.text-primary {
    color: #6592e6 !important;
}

.text-secondary {
    color: #ff6666 !important;
}

.text-success {
    color: #40b0bf !important;
}

.text-info {
    color: #47b5ed !important;
}

.text-warning {
    color: #ffe161 !important;
}

.text-danger {
    color: #d91515 !important;
    margin-bottom: 8px;
    margin-top: 9px;
    font-size: 0.9rem;
    font-weight: 400;
}

.text-white {
    color: #fafafa !important;
}

.text-black {
    color: #232323 !important;
}

a.text-primary:hover,
a.text-primary:focus,
a.text-primary.active {
    color: #205ac5 !important;
}

a.text-secondary:hover,
a.text-secondary:focus,
a.text-secondary.active {
    color: #ff0000 !important;
}

a.text-success:hover,
a.text-success:focus,
a.text-success.active {
    color: #266a73 !important;
}

a.text-info:hover,
a.text-info:focus,
a.text-info.active {
    color: #1283bc !important;
}

a.text-warning:hover,
a.text-warning:focus,
a.text-warning.active {
    color: #facb00 !important;
}

/*a.text-danger:hover,
a.text-danger:focus,
a.text-danger.active {
    color: #ff5500 !important;
}*/

a.text-white:hover,
a.text-white:focus,
a.text-white.active {
    color: #c7c7c7 !important;
}

a.text-black:hover,
a.text-black:focus,
a.text-black.active {
    color: #000000 !important;
}

a[class*="text-"]:not(.nav-link):not(.dropdown-item):not([role]):not(.navbar-caption) {
    position: relative;
    background-image: transparent;
    background-size: 10000px 2px;
    background-repeat: no-repeat;
    background-position: 0px 1.2em;
    background-position: -10000px 1.2em;
}

    a[class*="text-"]:not(.nav-link):not(.dropdown-item):not([role]):not(.navbar-caption):hover {
        transition: background-position 2s ease-in-out;
        background-image: linear-gradient(currentColor 50%, currentColor 50%);
        background-position: 0px 1.2em;
    }

.nav-tabs .nav-link.active {
    color: #6592e6;
}

.nav-tabs .nav-link:not(.active) {
    color: #232323;
}

.alert-success {
    /*background-color: #70c770;*/
}

.alert-info {
    /*background-color: #47b5ed;*/
}

.alert-warning {
    background-color: #ffe161;
}

.alert-note {
    color: #f00;
}

a,
a:hover {
    color: #6592e6;
}

.form-control {
    /*font-family: 'Roboto', sans-serif !important;*/
    font-size: 1rem !important;
    line-height: 1.5;
    font-weight: 400;
    /*border: 1px solid #C2C2C2;
    border-radius: 8px !important;*/
    /*height: 54px;*/
}

    .form-control:hover,
    .form-control:focus {
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px 0px, rgba(0, 0, 0, 0.07) 0px 1px 3px 0px, rgba(0, 0, 0, 0.03) 0px 0px 0px 1px;
        border-color: #6592e6 !important;
    }

    .form-control:-webkit-input-placeholder {
        font-family: 'Roboto', sans-serif;
        font-size: 1rem;
        line-height: 1.5;
        font-weight: 400;
        color: rgba(47,48,51,.75) !important;
    }

blockquote {
    border-color: #6592e6;
}

body {
    overflow-x: hidden;
}

a {
    transition: color 0.6s;
}

.footer-sec {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: #191823;
}

@media (max-width: 767px) {
    .footer-sec .row {
        text-align: center;
    }

        .footer-sec .row > div {
            margin: auto;
        }
}

.footer-sec .list {
    list-style: none;
    padding-left: 0;
    color: #bbbbbb;
}

@media (max-width: 991px) {
    .footer-sec .list {
        margin-bottom: 2rem;
    }
}

@media (min-width: 992px) {
    .footer-sec .list {
        margin-bottom: 0rem;
    }
}

.footer-sec .aob-text {
    color: #bbbbbb;
}

@media (max-width: 991px) {
    .footer-sec div > *:last-child {
        margin-top: 0 !important;
    }
}

@media (max-width: 992px) {
    .cid-sFF3mUy3tz .card-wrapper {
        margin-bottom: 2rem;
    }
}

.top-navbar {
    z-index: 1000;
    width: 100%;
    position: relative;
    min-height: 60px;
}

    .top-navbar nav.navbar {
        position: fixed;
    }

    .top-navbar .nav-dropdown .link {
        padding: 0 0.3em !important;
        margin: 0.667em 1em !important;
    }

        .top-navbar .nav-dropdown .link.dropdown-toggle::after {
            margin-left: 0.5rem;
            margin-top: 0.2rem;
        }

    .top-navbar .nav-link {
        position: relative;
    }

    .top-navbar .container {
        display: flex;
        margin: auto;
    }

@media (min-width: 992px) {
    .top-navbar .container {
        flex-wrap: nowrap;
    }
}

.top-navbar .dropdown-menu,
.top-navbar .navbar.opened {
    background: #ffffff !important;
}

.top-navbar .nav-item:focus,
.top-navbar .nav-link:focus {
    outline: none;
}

.top-navbar .navbar {
    min-height: 70px;
    transition: all 0.3s;
    border-bottom: 1px solid transparent;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    background: #ffffff;
}

    .top-navbar .navbar.opened {
        transition: all 0.3s;
    }

    .top-navbar .navbar .navbar-logo img {
        width: auto;
    }

    .top-navbar .navbar .navbar-collapse {
        justify-content: flex-end;
        z-index: 1;
    }

    .top-navbar .navbar.collapsed {
        justify-content: center;
    }

        .top-navbar .navbar.collapsed .nav-item .nav-link::before {
            display: none;
        }

@media (min-width: 992px) {
    .top-navbar .navbar.collapsed.opened:not(.navbar-short) .navbar-collapse {
        max-height: calc(98.5vh - 3rem);
    }
}

.top-navbar .navbar.collapsed ul.navbar-nav li {
    margin: auto;
}

@media (max-width: 991px) {
    .top-navbar .navbar .nav-item .nav-link::before {
        display: none;
    }

    .top-navbar .navbar .navbar-logo img {
        height: 3rem !important;
    }

    .top-navbar .navbar ul.navbar-nav li {
        margin: auto;
    }

    .top-navbar .navbar .navbar-brand {
        flex-shrink: initial;
        flex-basis: auto;
        word-break: break-word;
        padding-right: 2rem;
    }

    .top-navbar .navbar .navbar-toggler {
        flex-basis: auto;
    }
}

.top-navbar .navbar-brand {
    min-height: 70px;
    flex-shrink: 0;
    align-items: center;
    margin-right: 0;
    padding: 10px 0;
    transition: all 0.3s;
    word-break: break-word;
    z-index: 1;
}

    .top-navbar .navbar-brand .navbar-caption {
        line-height: inherit !important;
    }

    .top-navbar .navbar-brand .navbar-logo a {
        outline: none;
    }

.top-navbar .dropdown-item.active,
.top-navbar .dropdown-item:active {
    background-color: transparent;
}

.top-navbar .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0;
}

.top-navbar .nav-dropdown .link.dropdown-toggle {
    margin-right: 1.667em;
}

    .top-navbar .nav-dropdown .link.dropdown-toggle[aria-expanded="true"] {
        margin-right: 0;
        padding: 0.667em 1.667em;
    }

.top-navbar ul.navbar-nav {
    flex-wrap: wrap;
}

.top-navbar .navbar-buttons {
    text-align: center;
    min-width: 170px;
}

.top-navbar button.navbar-toggler {
    outline: none;
    width: 31px;
    height: 20px;
    cursor: pointer;
    transition: all 0.2s;
    position: relative;
    align-self: center;
}

    .top-navbar button.navbar-toggler .hamburger span {
        position: absolute;
        right: 0;
        width: 30px;
        height: 2px;
        border-right: 5px;
        background-color: #000000;
    }

        .top-navbar button.navbar-toggler .hamburger span:nth-child(1) {
            top: 0;
            transition: all 0.2s;
        }

        .top-navbar button.navbar-toggler .hamburger span:nth-child(2) {
            top: 8px;
            transition: all 0.15s;
        }

        .top-navbar button.navbar-toggler .hamburger span:nth-child(3) {
            top: 8px;
            transition: all 0.15s;
        }

        .top-navbar button.navbar-toggler .hamburger span:nth-child(4) {
            top: 16px;
            transition: all 0.2s;
        }

.top-navbar nav.opened .hamburger span:nth-child(1) {
    top: 8px;
    width: 0;
    opacity: 0;
    right: 50%;
    transition: all 0.2s;
}

.top-navbar nav.opened .hamburger span:nth-child(2) {
    transform: rotate(45deg);
    transition: all 0.25s;
}

.top-navbar nav.opened .hamburger span:nth-child(3) {
    transform: rotate(-45deg);
    transition: all 0.25s;
}

.top-navbar nav.opened .hamburger span:nth-child(4) {
    top: 8px;
    width: 0;
    opacity: 0;
    right: 50%;
    transition: all 0.2s;
}

.top-navbar .navbar-dropdown {
    padding: 0 1rem;
    position: fixed;
}

.top-navbar a.nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .top-navbar .navbar {
        height: 70px;
    }

        .top-navbar .navbar.opened {
            height: auto;
        }

    .top-navbar .nav-item .nav-link:hover::before {
        width: 175%;
        max-width: calc(100% + 2rem);
        left: -1rem;
    }
}
/*.aobfooter {
    background-image: url("../images/564567.jpg");
}
*/.login-hdr-sec {
    background-color: #efefef;
    background-image: url("../images/2435.jpg");
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
    /*height: 450px;*/
}

.login-hdr-sec1 {
    /*background-image: url("../images/login-hero.jpg");*/
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
}

/*@media (max-width: 1366px) {
    .login-hdr-sec {
        height: 450px;
    }

    .display-2 {
        font-size: 2.6rem;
    }
}

.login-hdr-sec .text-wrapper {
    padding-left: 3rem !important;   
}

.login-hdr-sec .row {
    align-items: center;
}
*/
.login-feature-one-sec {
    padding-top: 5rem;
    padding-bottom: 3rem;
    background-color: #fafafa;
    /*box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;*/
}

    .login-feature-one-sec .card-wrapper {
        display: flex;
    }

    .login-feature-one-sec .card-box {
    }

@media (max-width: 991px) {
    .login-feature-one-sec .card-wrapper {
        margin-bottom: 2rem;
    }
}

.login-feature-three-sec {
    padding-top: 6rem;
    padding-bottom: 6rem;
    background-color: #ffffff;
}

    .login-feature-three-sec .card-wrapper {
        padding-right: 2rem;
        padding-left: 2rem;
    }

@media (max-width: 992px) {
    .login-feature-three-sec .card {
        margin-bottom: 2rem !important;
    }

    .login-feature-three-sec .card-wrapper {
        padding: 0 1rem;
    }
}

@media (max-width: 767px) {
    .login-feature-three-sec .link-wrap {
        align-items: center;
    }
}

.login-feature-three-sec .image-wrapper img {
    width: 100%;
    object-fit: cover;
}

.login-feature-two-sec {
    padding-top: 5rem;
    padding-bottom: 4rem;
    background-color: #fafafa;
}

    .login-feature-two-sec img,
    .login-feature-two-sec .item-img {
        width: 100%;
    }

    .login-feature-two-sec .item:focus,
    .login-feature-two-sec span:focus {
        outline: none;
    }

    .login-feature-two-sec .item {
        cursor: pointer;
        margin-bottom: 2rem;
    }

    .login-feature-two-sec .item-wrapper {
        position: relative;
        border-radius: 4px;
        background: #ffffff;
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
    }

@media (min-width: 992px) {
    .login-feature-two-sec .item-wrapper .item-content {
        padding: 2rem 2rem 0;
    }

    .login-feature-two-sec .item-wrapper .item-footer {
        padding: 0 2rem 2rem;
    }
}

@media (max-width: 991px) {
    .login-feature-two-sec .item-wrapper .item-content {
        padding: 1rem 1rem 0;
    }

    .login-feature-two-sec .item-wrapper .item-footer {
        padding: 0 1rem 1rem;
    }
}

.login-feature-two-sec .mbr-section-btn {
    margin-top: auto !important;
}

.login-feature-two-sec .mbr-section-title {
    color: #232323;
}

.login-feature-two-sec .mbr-text,
.login-feature-two-sec .mbr-section-btn {
    text-align: left;
    color: #5f6366;
}

.login-feature-two-sec .item-title {
    text-align: left;
}

.login-feature-two-sec .item-subtitle {
    text-align: left;
}

.cls-1 {
    fill: #ff4141;
    fill-rule: evenodd;
}

.st0 {
    fill-rule: evenodd;
    clip-rule: evenodd;
}
/*.register-sec {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: #fafafa;
}*/

.register-sec {
    padding-top: 6rem;
    padding-bottom: 6rem;
    background-color: #ffffff;
}

@media (max-width: 991px) {
    .register-sec .image-wrapper {
        margin-bottom: 1rem;
    }
}

.register-sec .row {
    flex-direction: row;
}

.register-sec img {
    /*width: 100%;*/
}

@media (min-width: 992px) {
    .register-sec .text-wrapper {
        padding: 2rem;
    }
}

.forgetpwd-sec {
    padding-top: 6rem;
    padding-bottom: 6rem;
    background-color: #ffffff;
}

    .forgetpwd-sec .top-line {
        display: flex;
        justify-content: space-between;
    }

    .forgetpwd-sec .mbr-section-title,
    .forgetpwd-sec .mbr-section-subtitle {
        text-align: center;
    }

    .forgetpwd-sec .card:not(:nth-last-child(1)) {
        margin-bottom: 2rem;
    }

    .forgetpwd-sec .card-wrapper {
        border-radius: 4px;
    }

    .forgetpwd-sec .image-wrapper img {
        width: 100%;
        object-fit: cover;
    }

@media (max-width: 767px) {
    .forgetpwd-sec .card-box {
        padding: 1rem;
    }
}

@media (min-width: 768px) {
    .forgetpwd-sec .card-box {
        padding-right: 2rem;
    }
}

@media (min-width: 992px) {
    .forgetpwd-sec .card-box {
        padding-left: 2rem;
        padding-right: 4rem;
    }
}

.forgetpwd-img {
    min-height: 350px;
}

.alert-danger {
    line-height: 1.3;
    background-color: rgba(255,42,81,.1);
    border: 1px solid #cdcdcd;
    border-left: 5px solid #ff2a51;
    color: #f00;
    font-size: 1.1rem;
}
.alert-info {
    line-height: 1.3;
    /*background-color: #47b5ed;*/
    border: 1px solid #cdcdcd !important;
    /*border-left: 8px solid #1282BA !important;*/
    /*color: #f00;*/
    font-size: 1.1rem;
}
.alert {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
}

._loading_overlay_content {
    top: 45%;
    left: 35%;
    position: fixed;
}

.navmenusec {
    padding-top: 6.5rem;
    padding-bottom: 3.5rem;
    background-color: #ffffff;
}
.mainnavsec {
    /*padding-top: 1rem;*/
    padding-bottom: 3.5rem;
    background-color: #ffffff;
}
    .navmenusec .text-content {
        max-width: 50%;
    }

@media (max-width: 768px) {
    .navmenusec .text-content {
        max-width: none;
    }
}

@media (max-width: 768px) {
    .navmenusec .text-content * {
        text-align: center;
    }
}
/*.navmenusec .numberCircle:hover {
    border: 2px solid #0075be;
}*/
.numberCircle {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    padding: 6px 8px 0px;
    background: #fff;
    border: 3px solid #235ec9;
    color: black;
    text-align: center;
    font: 32px Arial, sans-serif;
    display: inline-block;
}

.card-wrapper-lightgray-bg {
    background: #fafafa;
}

.cid-tiptkotIfN .mbr-section-title,
.cid-tiptkotIfN .mbr-section-subtitle {
    text-align: center;
}

.cid-tiptkotIfN .cost {
    word-break: normal;
}

.cid-tiptkotIfN .card-wrapper {
    margin-bottom: 2rem;
    border-radius: 4px;
    background: #fafafa;
}

    .cid-tiptkotIfN .card-wrapper .top-line {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

@media (min-width: 768px) {
    .cid-tiptkotIfN .card-wrapper {
        padding: 2rem;
    }
}

@media (max-width: 767px) {
    .cid-tiptkotIfN .card-wrapper {
        padding: 1rem;
    }
}

.cid-tjk7GJmdgE {
    padding-top: 2.5rem;
    padding-bottom: 5rem;
    background-color: #ffffff;
}

.dot {
    height: 25px;
    max-height: 25px;
    width: 25px;
    max-width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

.usrappstatus {
    background-color: #fffde7;
    color: #3a3a3a;
    padding-left: 0.5rem;
    padding-right: 1rem;
    font-weight: 600;
    font-size: 1rem;
}

.thinbtn {
    padding: 6px 50px !important;
    margin: 0px;
    /*background-color: #fff1d9 !important;*/
}

.button-70 {
    background-image: linear-gradient(#0dccea, #0d70ea);
    border: 0;
    border-radius: 4px;
    /*box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;*/
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    margin: 5px;
    padding: 10px 15px;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    padding: 2px 30px 2px !important;
}

.button-10 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px 14px;
    border-radius: 6px;
    border: none;
    color: #fff;
    font-weight: 500;
    background: linear-gradient(180deg, #4B91F7 0%, #367AF6 100%);
    background-origin: border-box;
    box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

    .button-10:focus {
        box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2), 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
        outline: 0;
    }

.cls-1 {
    fill-rule: evenodd;
}
/*.badge {
    position: absolute;
    top: -8px;*/
/*padding: 5px 10px;*/
/*border-radius: 50%;
    background-color: red;
    color: white;
    display: inline-block;
}
.notification {*/
/*background-color: #555;
    color: white;
    text-decoration: none;
    padding: 15px 26px;*/
/*position: relative;*/
/*display: inline-block;
    border-radius: 2px;
}*/

.cls-1 {
    fill: #333b72;
}

.cls-2 {
    fill: #151b47;
}

.cls-3 {
    fill: #0099f5;
}

.cls-4 {
    fill: #0086f4;
}

.cls-5 {
    fill: #e2e2e2;
}

.pdashbrdsec .item-subtitle {
    text-align: left;
    color: #565959 !important;
}

.pdashbrdsec {
    padding-top: 3rem;
    padding-bottom: 6rem;
    background-color: #ffffff;
}

    .pdashbrdsec img,
    .pdashbrdsec .item-img {
        height: 100%;
        /*height: 300px;*/
        object-fit: cover;
    }

    .pdashbrdsec .item:focus,
    .pdashbrdsec span:focus {
        outline: none;
    }

    .pdashbrdsec .item {
        cursor: pointer;
        margin-bottom: 2rem;
    }

    .pdashbrdsec .item-wrapper {
        position: relative;
        border-radius: 4px;
        background: #fafafa;
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        margin-left: 4rem !important;
        margin-right: 4rem !important;
        /*margin-bottom: 2rem !important;*/
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    }

    .pdashbrdsec .item-content {
    }

@media (min-width: 992px) {
    .pdashbrdsec .item-wrapper .item-content {
        padding: 2rem 2.5rem 0;
    }

    .pdashbrdsec .item-wrapper .item-footer {
        padding: 0 2rem 2rem;
    }
}

@media (max-width: 991px) {
    .pdashbrdsec .item-wrapper .item-content {
        padding: 1rem 1rem 0;
    }

    .pdashbrdsec .item-wrapper .item-footer {
        padding: 0 1rem 1rem;
    }
}

.pdashbrdsec .mbr-section-btn {
    margin-top: auto !important;
}

.pdashbrdsec .mbr-section-title {
    color: #232323;
}

.pdashbrdsec .mbr-text,
.pdashbrdsec .mbr-section-btn {
    text-align: left;
}

.pdashbrdsec .item-title {
    text-align: left;
}

.pdashbrdsec .item-subtitle {
    text-align: left;
    color: #232323;
}

.btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary:focus {
    color: #235ec9;
    border-color: #235ec9 !important;
    border-width: 3px;
}

    .btn-outline-primary:hover {
        color: #ffffff !important;
        background-color: #2260d2 !important;
        border-color: #2260d2 !important;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    }

.disabled {
    opacity: 0.4 !important;
    cursor: not-allowed !important;
    pointer-events: auto !important;
}

.modal-header {
    border: none !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.modal-footer {
    border: none !important;
    justify-content: flex-start !important;
}

.modal-body {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.modal-footer {
    /*margin: 0px !important;*/
}



.enrfrmsec, .app-dbrd-sec {
    /*padding-top: 1rem;*/
    padding-bottom: 6rem;
}

    .enrfrmsec .card:not(:nth-last-child(1)) {
        margin-bottom: 2rem;
    }

    .enrfrmsec .card-wrapper {
        background-color: #ffffff;
        border-radius: 4px;
    }

    .enrfrmsec .image-wrapper img {
        width: 100%;
        object-fit: cover;
    }

    .enrfrmsec .social-row .soc-item {
        display: inline-block;
        text-align: center;
        border-radius: 50%;
        margin-right: 0.6rem;
        margin-bottom: 1rem;
        padding: 0.5rem;
        border: 2px solid #6592e6;
        transition: all 0.3s;
    }

        .enrfrmsec .social-row .soc-item .mbr-iconfont {
            display: flex;
            justify-content: center;
            align-content: center;
            color: #6592e6;
            font-size: 1.5rem;
            transition: all 0.3s;
        }

        .enrfrmsec .social-row .soc-item:hover {
            background-color: #6592e6;
        }

            .enrfrmsec .social-row .soc-item:hover .mbr-iconfont {
                color: #ffffff;
            }

@media (max-width: 767px) {
    .enrfrmsec .card-box {
        padding: 1rem;
    }
}

@media (min-width: 768px) {
    .enrfrmsec .card-box {
        padding-right: 2rem;
    }
}

@media (min-width: 992px) {
    .enrfrmsec .card-box {
        padding-left: 2rem;
        padding-right: 4rem;
    }
}

.enrfrmsec {
    /*padding-top: 2rem;*/
    padding-bottom: 1rem;
    background-color: #ffffff;
}

.enrfrmsec .item {
    display: flex;
    padding-left: 1rem;
}

@media (min-width: 991px) {
    .enrfrmsec .item {
    }
}

.enrfrmsec .item:last-child .icon-box:before {
    display: none;
}

.enrfrmsec .item.last .icon-box:before {
    display: none;
}

.enrfrmsec .icon-box, .enrfrmsec .icon-box-faded {
    /*background: #235ec9;*/
    width: 34px;
    min-width: 34px;
    height: 34px;
    border-radius: 50%;
    margin-right: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /*border: 2px solid #235ec9;*/
}
.enrfrmsec .icon-box-faded {
    /*border: 2px solid #86A9EA;*/
}
    /*.icon-box::before {
    content: "";
    width: 70%;
    height: 70%;
    position: absolute;
    border-radius: 50%;
    background-color: transparent;
    border: .125rem solid #f9f9f9;
}*/
    @media (max-width: 768px) {
        .enrfrmsec .icon-box {
        margin-right: 1rem;
    }
}

/*.enrfrmsec .icon-box::before {
    content: "\e925";
    font-family: Moririse2 !important;
    position: absolute;
    font-size: 32px;
    left: 50%;
    top: 120%;
    color: #6592e6;
    transform: translate(-50%, 0);
}*/

.enrfrmsec .step-number {
    /*font-size: 2rem;*/
    /*color: #235ec9;*/
}
.enrfrmsec .step-number-faded {
    /*font-size: 2rem;*/
    /*color: #86A9EA;*/
}
@media (max-width: 991px) {
    .enrfrmsec .card {
        margin-bottom: 2rem;
    }

    .enrfrmsec .card-wrapper {
        margin-bottom: 2rem;
    }

    .enrfrmsec .icon-box::before {
        top: 141%;
    }
}

@media (max-width: 768px) {
    .enrfrmsec .icon-box::before {
        top: 114%;
    }
}

.mailsec .searchbox .btn {
    margin: 0 !important;
}

.pre-login-menu-sec {
    z-index: 1000;
    width: 100%;
    position: relative;
    min-height: 60px;
}

    .pre-login-menu-sec nav.navbar {
        position: fixed;
    }

    .pre-login-menu-sec .nav-dropdown .link {
        padding: 0 0.3em !important;
        margin: 0.667em 1em !important;
    }

        .pre-login-menu-sec .nav-dropdown .link.dropdown-toggle::after {
            margin-left: 0.5rem;
            margin-top: 0.2rem;
        }

    .pre-login-menu-sec .nav-link {
        position: relative;
    }

    .pre-login-menu-sec .container {
        display: flex;
        margin: auto;
    }

@media (min-width: 992px) {
    .pre-login-menu-sec .container {
        flex-wrap: nowrap;
    }
}

.pre-login-menu-sec .dropdown-menu,
.pre-login-menu-sec .navbar.opened {
    background: #ffffff !important;
}

.pre-login-menu-sec .nav-item:focus,
.pre-login-menu-sec .nav-link:focus {
    outline: none;
}

.pre-login-menu-sec .navbar {
    min-height: 70px;
    transition: all 0.3s;
    border-bottom: 1px solid transparent;
    /*box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);*/
    background: #ffffff;
}

    .pre-login-menu-sec .navbar.opened {
        transition: all 0.3s;
    }

    .pre-login-menu-sec .navbar .navbar-logo img {
        width: auto;
    }

    .pre-login-menu-sec .navbar .navbar-collapse {
        justify-content: flex-end;
        z-index: 1;
    }

    .pre-login-menu-sec .navbar.collapsed {
        justify-content: center;
    }

        .pre-login-menu-sec .navbar.collapsed .nav-item .nav-link::before {
            display: none;
        }

@media (min-width: 992px) {
    .pre-login-menu-sec .navbar.collapsed.opened:not(.navbar-short) .navbar-collapse {
        max-height: calc(98.5vh - 3rem);
    }
}

.pre-login-menu-sec .navbar.collapsed ul.navbar-nav li {
    margin: auto;
}

@media (max-width: 991px) {
    .pre-login-menu-sec .navbar .nav-item .nav-link::before {
        display: none;
    }

    .pre-login-menu-sec .navbar .navbar-logo img {
        height: 3rem !important;
    }

    .pre-login-menu-sec .navbar ul.navbar-nav li {
        margin: auto;
    }

    .pre-login-menu-sec .navbar .navbar-brand {
        flex-shrink: initial;
        flex-basis: auto;
        word-break: break-word;
        padding-right: 2rem;
    }

    .pre-login-menu-sec .navbar .navbar-toggler {
        flex-basis: auto;
    }
}

.pre-login-menu-sec .navbar-brand {
    min-height: 70px;
    flex-shrink: 0;
    align-items: center;
    margin-right: 0;
    padding: 10px 0;
    transition: all 0.3s;
    word-break: break-word;
    z-index: 1;
}

    .pre-login-menu-sec .navbar-brand .navbar-caption {
        line-height: inherit !important;
    }

    .pre-login-menu-sec .navbar-brand .navbar-logo a {
        outline: none;
    }

.pre-login-menu-sec .dropdown-item.active,
.pre-login-menu-sec .dropdown-item:active {
    background-color: transparent;
}

.pre-login-menu-sec .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0;
}

.pre-login-menu-sec .nav-dropdown .link.dropdown-toggle {
    margin-right: 1.667em;
}

    .pre-login-menu-sec .nav-dropdown .link.dropdown-toggle[aria-expanded="true"] {
        margin-right: 0;
        padding: 0.667em 1.667em;
    }

.pre-login-menu-sec ul.navbar-nav {
    flex-wrap: wrap;
}

.pre-login-menu-sec .navbar-buttons {
    text-align: center;
    min-width: 170px;
}

.pre-login-menu-sec button.navbar-toggler {
    outline: none;
    width: 31px;
    height: 20px;
    cursor: pointer;
    transition: all 0.2s;
    position: relative;
    align-self: center;
}

    .pre-login-menu-sec button.navbar-toggler .hamburger span {
        position: absolute;
        right: 0;
        width: 30px;
        height: 2px;
        border-right: 5px;
        background-color: #000000;
    }

        .pre-login-menu-sec button.navbar-toggler .hamburger span:nth-child(1) {
            top: 0;
            transition: all 0.2s;
        }

        .pre-login-menu-sec button.navbar-toggler .hamburger span:nth-child(2) {
            top: 8px;
            transition: all 0.15s;
        }

        .pre-login-menu-sec button.navbar-toggler .hamburger span:nth-child(3) {
            top: 8px;
            transition: all 0.15s;
        }

        .pre-login-menu-sec button.navbar-toggler .hamburger span:nth-child(4) {
            top: 16px;
            transition: all 0.2s;
        }

.pre-login-menu-sec nav.opened .hamburger span:nth-child(1) {
    top: 8px;
    width: 0;
    opacity: 0;
    right: 50%;
    transition: all 0.2s;
}

.pre-login-menu-sec nav.opened .hamburger span:nth-child(2) {
    transform: rotate(45deg);
    transition: all 0.25s;
}

.pre-login-menu-sec nav.opened .hamburger span:nth-child(3) {
    transform: rotate(-45deg);
    transition: all 0.25s;
}

.pre-login-menu-sec nav.opened .hamburger span:nth-child(4) {
    top: 8px;
    width: 0;
    opacity: 0;
    right: 50%;
    transition: all 0.2s;
}

.pre-login-menu-sec .navbar-dropdown {
    padding: 0 1rem;
    position: fixed;
}

.pre-login-menu-sec a.nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .pre-login-menu-sec .navbar {
        height: 70px;
    }

        .pre-login-menu-sec .navbar.opened {
            height: auto;
        }

    .pre-login-menu-sec .nav-item .nav-link:hover::before {
        width: 175%;
        max-width: calc(100% + 2rem);
        left: -1rem;
    }
}
.dropdown-menu a {
    color: #333333 !important;
    line-height: 1.7 !important;
}
.dropdown-menu {
    font-size: 1.1rem;
/*    padding: 1.1rem 0rem;
*/    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
._loading_overlay_content {
    top: unset;
    left: unset;
    position: unset;
}
._loading_overlay_overlay {
    background: rgba(0, 0, 0, 0.0);
}
.breadcrumb {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
/*.enrfrmsec .card .nav-link.active {
    opacity: 0.7;
}*/
.step-number-active::before {
    content: "";
    width: 130%;
    height: 130%;
    position: absolute;
    border-radius: 50%;
    background-color: transparent;
    border: 0.125rem solid #f5f5f5;
}


.app-sec-nav .step-number, .step-number-active {
    background-color: #f5f5f5;
    transition: all .15s linear;
    justify-content: center;
    line-height: inherit;
    align-items: center;
    color: #ADADAD;
    position: absolute;
    border-radius: 50%;
    font-weight: 700;
    display: flex;
    height: 1.9rem;
    width: 1.9rem;
    font-weight: 700;
    /*margin-left: 76px;*/
}
.app-sec-nav .step-number-active {
    background-color: #00398F;
    color: #ffffff;
    margin-top: -4px;
}

/*.fb-render-preview .step-number-active::before {
    content: "";
    width: 130%;
    height: 130%;
    position: absolute;
    border-radius: 50%;
    background-color: transparent;
    border: 0.125rem solid #f5f5f5;
}


.fb-render-preview .app-sec-nav .step-number, .step-number-active {
    background-color: #f5f5f5;
    transition: all .15s linear;
    justify-content: center;
    line-height: inherit;
    align-items: center;
    color: #ADADAD;
    position: absolute;
    border-radius: 50%;
    font-weight: 700;
    display: flex;
    height: 2.9rem;
    width: 2.9rem;
    font-weight: 700;*/
    /*margin-left: 76px;*/
/*}

.fb-render-preview .app-sec-nav .step-number-active {
    background-color: #009dff;
    color: #ffffff;
    margin-top: -4px;
}*/

.app-sec-nav .text-box,
.app-sec-nav .text-box-active {
    /*padding-left: 25px;*/
    padding-bottom: 3.5rem;
}
.app-sec-nav .text-box {
    border-left: 2px solid #ccc;
}
.app-sec-nav .text-box-active {
    border-left: 2px solid #007db8;
}
.app-sec-nav-link-active {
    color: #333333 !important;
    font-weight: 600;
}
.app-sec-nav-link {
    color: #ccc !important;
}
.app-sec-nav-link, .app-sec-nav-link-active {
    text-decoration: none;
    padding-top: 0.5rem;
    padding-left: 0.5rem;
}
.app-sec-nav-link:hover {
    color: #333333 !important;
    font-weight: 600;
}
.app-sec-nav-link-active:hover {
    text-decoration: none !important;
    cursor: default;
}

.formbuilder-select-label {
}
.rendered-form-acct label {
    font-size: 1.38rem;
    line-height: 1.5;
    padding-bottom: 0.5rem !important;
    color: #333333;
}

.rendered-form-acct .form-group {
    padding-bottom: 1rem !important;
}
.form-control {
    background-color: #ffffff;
    background-clip: border-box;
    /*color: rgba(0, 0, 0, 0.87) !important;*/
    /*line-height: 1rem !important;*/
    /*height: auto;*/
    padding: 2px 12px !important;
    transition: border-color 0.25s ease 0s;
    height: 40px;
    border: 1px solid #7d8998;
    /*border-radius: 4px;*/
    /*box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px 0px, rgba(0, 0, 0, 0.07) 0px 1px 3px 0px, rgba(0, 0, 0, 0.03) 0px 0px 0px 1px;*/
}

.form-select {
    font-size: 1rem !important;
    background-color: #ffffff;
    /*color: rgba(0, 0, 0, 0.87) !important;*/
    /*line-height: 1rem !important;*/
    height: 40px;
    /*padding: 0.9rem 1rem;*/
    transition: border-color 0.25s ease 0s;
    border: 1px solid #7d8998;
    /*border-radius: 8px;*/
    /*box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px 0px, rgba(0, 0, 0, 0.07) 0px 1px 3px 0px, rgba(0, 0, 0, 0.03) 0px 0px 0px 1px;*/
}
.form-label {
    margin-bottom: 0.5rem;
    color: #000;
    font-weight: 500 !important;
}
.fb-render label {
    margin-bottom: 0.5rem;
    color: #000;
}
.rendered-form-acct .btn {
    margin-left: 0px !important;
}
.rendered-form-acct {
    min-height: 300px;
    border-left: 1px solid #C2C2C2;
}
.form-group .formbuilder-required {
    color: #f00 !important;
    padding-left: 5px;
}
.rendered-form-acct .tooltip-element {
    background: #00f;
    margin-bottom: 5px !important;
}
.rendered-form .tooltip-element {
    background: #002d72 !important;
    padding-bottom: 5px;
}
.showMain {
    display: block;
}
.hideMain {
    display: none;
}

.form-control:disabled, .form-control[readonly] {
    background-color: rgba(204, 204, 204, 0.5);
    color: #8F8F8F;
}
#cboTemplate {
    background-color: #fff !important;
}
.prof-pic {
    width: 150px !important;
    display: inline-block;
}
.prof-pic-prvw {
    max-width: 160px !important;
    display: inline-block;
}
.navmenusec .dropdown-menu {
    padding: 0 !important;
}
.rounded-circle {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}
.avatar-circle {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    border-radius: 50%;
    height: 4rem;
    width: 4rem;
    background-color: #f00;
}
.userprofsec {
    padding-bottom: 3rem;
}

.userprofsec .list-group-item {
    padding-top: 1rem !important;
    padding-bottom: 0.8rem !important;
    color: #333333 !important;
}

.navmenusec a {
    text-decoration: none;
    color: #333333 !important;
}

.per-info-sec label {
}
.aob-req-field {
    color: #f00;
}
.aob-req-field sup {
    font-size: 1rem !important;
}
.aob-text-primary {
    color: #07122b;
}

#cboTemplate .form-control:disabled {
    background-color: #fff !important;
}
.formbuilder-file-label {
    padding-bottom: 8px;
}
.rendered-form .formbuilder-checkbox-group input[type=checkbox], 
.rendered-form .formbuilder-checkbox-group input[type=radio], 
.rendered-form .formbuilder-radio-group input[type=checkbox], 
.rendered-form .formbuilder-radio-group input[type=radio]
{
    margin-right: 10px;
}
tr[class$="MuiTableRow-root"] {
    box-shadow: none;
}
/*.css-cts2rn-MuiPaper-root-MuiAlert-root, .css-joff11-MuiPaper-root-MuiAlert-root {
    background-color: rgba(255,42,81,.1) !important;
    border: 1px solid #cdcdcd;
    border-left: 5px solid #ff2a51;
    color: #f00;
    margin-bottom: 2rem !important;
}

.css-1b6zum-MuiPaper-root-MuiAlert-root {
    background-color: rgba(241,243,244,0.871) !important;
    width: 100% !important;*/
/*text-align: right;*/
/*padding: 0 !important;
    font-size: 0.9rem !important;
}

.css-1pxa9xg-MuiAlert-message {
    width: 100% !important;
}
.css-4sd9dj {
    padding-left: 0px !important;
}*/
/*.upld-prof-pic-btn {

}*/
.cls-1 {
    fill: #0099f5;
}

.cls-1, .cls-2, .cls-4, .cls-5 {
    stroke: #231f20;
}

.cls-1, .cls-2, .cls-3, .cls-5 {
    stroke-linejoin:bevel;
}

.cls-1, .cls-2, .cls-3, .cls-4, .cls-5 {
    stroke-width:1.5px;
}

.cls-2 {
    fill: none;
}

.cls-3, .cls-5 {
    fill: #ffd44d;
}

.cls-3 {
    stroke: #fff;
}

.cls-4 {
    fill: #231f20;
    stroke-miterlimit: 10;
}

.cls-1 {
    fill: #0099f5;
}

.cls-2 {
    fill: #0086f4;
}

.cls-3 {
    fill: none;
    stroke: #24285b;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 9px;
}

.cls-4 {
    fill: #ededeb;
}

.cls-5 {
    fill: #f0457d;
}

.cls-6 {
    fill: #db2868;
}

.cls-1 {
    fill: #24285b;
}

.cls-2 {
    fill: #0099f5;
}

.cls-3 {
    fill: #0086f4;
}

.cls-4 {
    fill: #14abf7;
}

.cls-1 {
    fill: #f0457d;
}

.cls-2 {
    fill: #db2868;
}

.cls-3 {
    fill: #0099f5;
}

.cls-4 {
    fill: #0086f4;
}

.cls-5 {
    fill: #ffd44d;
}
.cls-1 {
    fill: #333b72;
}

.cls-2 {
    fill: #151b47;
}

.cls-3 {
    fill: #0099f5;
}

.cls-4 {
    fill: #0086f4;
}

.cls-5 {
    fill: #e2e2e2;
}
.cls-1 {
    fill: #69d7f9;
}

.cls-2 {
    fill: none;
    stroke: #3a00ba;
    stroke-miterlimit: 10;
    stroke-width: 2px;
}
div.vertical-line {
    margin-top: 2px;
    width: 1px; /* Line width */
    background-color: #333; /* Line color */
    height: 15px; /* Override in-line if you want specific height. */
    float: left; /* Causes the line to float to left of content. 
        You can instead use position:absolute or display:inline-block
        if this fits better with your design */
}